// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import vNotify from "./vanilla-notify"
import BSN from "bootstrap.native"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)

application.load(definitionsFromContext(context))

application.startCallback = function() {
  const currentTransportVersion = "0.1.7";
  const localTransportVersion = window.localStorage.getItem('transport_version') || "0";

  if (localTransportVersion !== currentTransportVersion) {
    window.localStorage.clear();
    window.localStorage.setItem('transport_version', currentTransportVersion);
  }
}

application.startCallback();

ActiveStorage.start()
Rails.start()
window.Rails = Rails;

// window click
// Close the dropdown if the user clicks outside of it
window.onclick = function (event) {
  if (event.target.closest('.drop-menu') === null || event.target.matches('.drop-close')) {
    if (!event.target.matches('.drop-toggle')) {
      var dropdowns = document.getElementsByClassName("drop-menu");

      for (var i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }
}

window.bsn = BSN
