import { ApplicationController } from "../application_controller";
import AutoNumeric from 'autonumeric';
import BSN from "bootstrap.native";

export default class extends ApplicationController {
  static targets = ["transaction_incomes_table", "modal_currency_text",
    "transaction_currency_radio_template", "modal_currencies_radio",
    "to_main_rate_field", "from_main_rate_field",
    "to_additional_rate_field", "from_additional_rate_field",
    "filter_service_types", "filter_suppliers_ids", "filter_cities_ids"
  ]


  connect() {
    if (this.hasTransaction_incomes_tableTarget) {
      this.loadTransactionIncomesTable();
    }
  }


  //
  // Forms
  //

  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  toggleButton(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let dropdown = new BSN.Dropdown(target);
    dropdown.toggle();
  }

  collapseExpensesAll(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let accordion_target = document.querySelector(target.getAttribute("data-target"));
    let hide_elements = target.classList.contains("show-all");

    target.classList.toggle("show-all");
    accordion_target.classList.toggle("show-all");

    if (hide_elements) {
      accordion_target.querySelectorAll(".accordion__item__header:not(.collapsed)").forEach(e => {
        this.collapseClose(e)
        // let collapse = new BSN.Collapse(e)
        // collapse.hide();
      })
    } else {
      accordion_target.querySelectorAll(".accordion__item__header.collapsed").forEach(e => {
        this.collapseOpen(e)
        // let collapse = new BSN.Collapse(e)
        // collapse.show();
      })
    }
  }

  collapseExpenseSection(event) {
    event.preventDefault();
    let target = event.currentTarget;
    // let collapse = nil
    if (!target.collapse) target.collapse = new BSN.Collapse(target)
    console.log(target.collapse)

    // target.collapse.toggle();
    if (target.classList.contains("collapsed"))
      target.collapse.show();
    else target.collapse.hide();
  }

  collapseOpen(target) {
    console.log(target)
    // let collapse = nil
    if (!target.collapse) target.collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed")) target.collapse.show();
  }

  collapseClose(target) {
    console.log(target)
    // let collapse = nil
    if (!target.collapse) target.collapse = new BSN.Collapse(target)
    if (!target.classList.contains("collapsed")) target.collapse.hide();
  }



  filterExpenseTable(event) {
    let filters = {};

    if (this.hasFilter_service_typesTarget) {
      filters["service_types"] = this.filter_service_typesTarget.choices.getValue(true);
    }

    if (this.hasFilter_suppliers_idsTarget) {
      filters["suppliers_ids"] = this.filter_suppliers_idsTarget.choices.getValue(true);
    }

    if (this.hasFilter_cities_idsTarget) {
      filters["cities_ids"] = this.filter_cities_idsTarget.choices.getValue(true);
    }

    let url = this.data.get("table-load-url");
    let url_params = new URLSearchParams(filters);
    const content_element = document.getElementById("deal_expense_transactions_table");
    url_params.append("transaction_type", "expense");

    fetch(url + "?" + url_params, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          content_element.innerHTML = JSON.parse(content)["page_content"];
        })
      }
    });

  }

  updateTransactionCurrencies(event) {
    let currencies = event.currentTarget.getAttribute("data-account-currencies");
    let li_template = this.transaction_currency_radio_templateTarget.innerHTML;
    let currencies_ul = this.modal_currencies_radioTarget;

    currencies_ul.innerHTML = "";
    if (currencies) {
      JSON.parse(currencies).forEach(el => {
        let li = document.createElement("li");
        let currency_class = (el["disabled"] == true ? "disabled" : "")
        let li_html = li_template.replace(/TRANSACTION_AMOUNT_CURRENCY_CODE/g, el["currency"]);
        li_html = li_html.replace(/TRANSACTION_AMOUNT_CURRENCY_CLASS/g, currency_class);
        li_html = li_html.replace(/\"TRANSACTION_CURRENCY_INFO\"/g, JSON.stringify(el));

        li.classList.add("nav-item");
        li.innerHTML = li_html;
        currencies_ul.appendChild(li);
      });

      let currency = currencies_ul.querySelector("li:first-child input[type=radio]");
      currency.checked = true;

      this.updateTransactionInputCurrencies(currency);
    }
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget;

    this.updateTransactionInputCurrencies(currency);
  }

  updateTransactionInputCurrencies(element) {
    let currency = element.getAttribute("value");
    let info_data = element.getAttribute("data-currency-info");
    let to_main_field, from_main_field, to_additional_field, from_additional_field;
    // to_main_field = this.to_main_rate_fieldTarget;
    from_main_field = this.from_main_rate_fieldTarget;
    to_additional_field = this.to_additional_rate_fieldTarget;
    // from_additional_field = this.from_additional_rate_fieldTarget;

    this.modal_currency_textTarget.innerHTML = currency;

    if (info_data) {
      info_data = JSON.parse(info_data);

      if (info_data["is_main"] == true) {
        //   to_main_field.classList.add("hidden");
        //   AutoNumeric.set(to_main_field.querySelector(".input-group input"), 1.0);

        from_main_field.classList.add("hidden");
        AutoNumeric.set(from_main_field.querySelector(".input-group input"), 1.0);
      } else {
        //   to_main_field.classList.remove("hidden");
        //   to_main_field.querySelector("label.control-label span.text").innerHTML = info_data["label"] + ' = ';
        //   AutoNumeric.set(to_main_field.querySelector(".input-group input"), info_data["to_main_rate"] || 1.0);

        from_main_field.classList.remove("hidden");
        from_main_field.querySelector(".input-group span.input-group-text").innerHTML = currency;
        AutoNumeric.set(from_main_field.querySelector(".input-group input"), info_data["from_main_rate"] || 1.0);
      }

      if (info_data["is_additional"] == true) {
        to_additional_field.classList.add("hidden");
        AutoNumeric.set(to_additional_field.querySelector(".input-group input"), 1.0);

        //   from_additional_field.classList.add("hidden");
        //   AutoNumeric.set(from_additional_field.querySelector(".input-group input"), 1.0);
      } else {
        to_additional_field.classList.remove("hidden");
        to_additional_field.querySelector("label.control-label span.text").innerHTML = info_data["label"] + ' = ';
        AutoNumeric.set(to_additional_field.querySelector(".input-group input"), info_data["to_additional_rate"] || 1.0);

        //   from_additional_field.classList.remove("hidden");
        //   from_additional_field.querySelector(".input-group span.input-group-text").innerHTML = currency;
        //   AutoNumeric.set(from_additional_field.querySelector(".input-group input"), info_data["from_additional_rate"] || 1.0);
      }
    }
  }

  async loadTransactionIncomesTable() {
    const url = this.data.get("tab-content-url");
    const tab_target = this.transaction_incomes_tableTarget;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      // if (response.status == 200) {
      response.text().then((content) => {
        tab_target.innerHTML = content;
      })
      // }
    });
  }


}